<script lang="ts" setup>
    interface Props {
        project: Project
        grid?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        grid: true,
    })

    const brokenTitle = () => {
        const titlePieces = props.project.title.split(', ')

        if (titlePieces.length == 2) {
            return titlePieces.join(',<br>')
        }

        if (titlePieces.length >= 3) {
            return titlePieces.slice(0, -2).join(', ') + ',<br>' + titlePieces.slice(-2).join(', ')
        }

        return props.project.title
    }
</script>

<template>
    <NuxtLink :to="project.url">
        <div>
            <Asset :asset="project.feature_image" class="project-item-asset text-sm">
                <div
                    class="icon-arrow-northeast icon-large relative uppercase after:absolute after:right-0 after:top-0"
                >
                    <p class="pr-6" v-html="brokenTitle()"></p>
                </div>
            </Asset>
        </div>
    </NuxtLink>
</template>
